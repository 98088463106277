@use 'sass:map';
@use '~@angular/material' as mat;

// Example for custom theming
@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);
  $background: map.get($theme, 'background');
  $foreground: map.get($theme, 'foreground');

  $row-hover-bg: mat.get-color-from-palette(mat.$indigo-palette, 50);
  $row-odd-bg: mat.get-color-from-palette(mat.$gray-palette, 100);

  $row-hover-bg-dark: mat.get-color-from-palette(mat.$blue-gray-palette, 900);
  $row-odd-bg-dark: lighten(mat.get-color-from-palette(mat.$gray-palette, 900), 10%);

  mtx-grid.mtx-grid {
    .mat-table {
      &.mat-table-striped {
        .mat-row-odd {
          background-color: if($is-dark-theme, $row-odd-bg-dark, $row-odd-bg);
        }
      }

      &.mat-table-hover {
        .mat-row {
          &:hover {
            background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
          }
        }
      }

      .mat-row {
        &.selected {
          background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
        }
      }
    }
  }
}
