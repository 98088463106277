/** Fix some Material component styles */

@use '../../app/theme/style/variables';

.mat-card {
  margin-bottom: variables.$gutter;
}

.mat-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.mat-list-base {
  position: relative;
}

.form-field-full {
  .mat-form-field {
    width: 100%;
  }
}
